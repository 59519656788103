import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const FuelCells = () => (
  <Layout isPagina={true}>
    <SEO
      title="Micro Cogenerazione a celle di combustile - Generatore di Elettricità"
      description="Il più efficente generatore di elettricità. Installazioni in tutto il Lazio"
      location="fuel-cells/"
    />
    <BanneronePage title="Fuel Cells" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Micro cogenerazione a celle di combustibile a Roma, Nettuno, Anzio, Pomezia, Latina, Aprilia" />
      <CardPagina>
        <p className="text-center">
          <b>
            {" "}
            Il più efficente generatore di elettricità e calore al mondo. <br />{" "}
          </b>
        </p>
        <Row>
          <Col xs={12}>
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../fuel/micro-cogenerazione-a-celle-di-combustibile.jpg"
              alt="Grafico della cogenerazione a celle di combustibile"
            />
          </Col>
        </Row>

        <p className="text-center">
          <b>
            In alternativa o ad integrazione del fotovoltaico:
            <br />
          </b>
          Tempi di ammortamento di 4-5 anni con la detrazione fiscale (IRES o
          IRPEF) del 65% <br /> Defiscalizzazione costo del gas (no tax)
          <br /> Deducibilità 100% <br /> Scambio sul posto per l'energia
          immessa in rete
        </p>

        <p className="gridParity text-center">
          Produzione di Energia Elettrica + Termica (ACS o riscaldamento) ad
          elevatissima efficienza, 24 h al giorno!
        </p>
      </CardPagina>
      <Sezione title="Opportunità per Aziende e Privati">
        <CardPagina variante="bordoLeft bordoRight">
          <p>
            <b>
              Perchè installare un impianto a MICRO-COGENERAZIONE a Celle di
              Combustibile?
            </b>
          </p>
          <p>
            <u>
              <b>I risparmi sulla bolletta sono considerevoli.</b>
            </u>
            <br />
            Un impianto a micro-cogenerazione di NSE srl è in grado di{" "}
            <u>
              produrre direttamente dove e quando serve sia energia elettrica
              che termica
            </u>
            . Cogenerare l’energia ove essa è necessaria, oltre ad essere una
            scelta eco-sostenibile, è soprattutto una scelta economicamente
            vantaggiosa:{" "}
            <u>
              il costo del kWh elettrico risulta infatti inferiore a quello
              acquistato da rete
            </u>{" "}
            . Diversamente da altri interventi di efficienza energetica, un
            impianto a cogenerazione, grazie alla durata della macchina, alle
            detrazioni fiscali del 65%/incentivi del GSE ed ai costi di
            manutenzione contenuti, risulta essere un vero e proprio
            investimento finanziario con tempi di rientro molto brevi
            (mediamente intorno ai 2-3 anni). Dotare il proprio immobile o la
            propria attività commerciale di un impianto a cogenerazione
            significa inoltre aumentarne considerevolmente il valore.
          </p>
          <Row>
            <Col xs={12}>
              <img
                className="rounded mt-1 mx-auto img-fluid "
                src="../fuel/bluegen4.jpg"
                alt="Generatore BlueGen Lazio"
              />
            </Col>
          </Row>
          <p>
            <u>
              <b>Eleveta efficienza</b>
            </u>{" "}
            <br />
            Il micro-cogeneratore a Fuel Cells di <b>NSE srl</b> funziona a gas
            naturale di rete e genera elettricità per la tua
            casa/ufficio/attività/azienda. Riduce sensibilmente la quantità di
            energia acquistata dalla rete. Il calore cogenerato fornirà
            giornalmente fino a 200 litri di acqua calda, riducendo così il
            fabbisogno di energia del tuo sistema di riscaldamento o dell’acqua
            calda sanitaria. Il sistema garantisce una maggiore effi cienza di
            generazione, alimentando direttamente le tue utenze. Il sistema
            trasforma il gas naturale in elettricità che, comparata a quella
            acquistata dalla rete risulta maggiormente conveniente. In questo
            modo le bollette e le emissioni di CO2 possono essere ridotte fino
            ad un 50%. Il sistema innovativo è una macchina di
            micro-cogenerazione di calore ed energia elettrica (mCHP). Utilizza
            <u>l’innovativa tecnologia delle celle a combustibile</u> per
            scomporre il gas naturale in ossidi di carbonio ed idrogeno con una
            reazione elettrochimica, da cui si ricava elettricità pulita in loco
            e controllabile, per utenze di tipo residenziale o commerciale. Una
            singola macchina è in grado di produrre fi no a 13.000 kWh di
            elettricità per anno. Perfetto per situazioni dove il fabbisogno
            energetico annuale ecceda i 10.000 kWh – in un singolo edifici o in
            una piccola azienda. Più macchine in parallelo per fabbisogni
            energetici maggiori.
          </p>
          <Row>
            <Col xs={12}>
              <img
                className="rounded mt-1 mx-auto img-fluid "
                src="../fuel/alim-e-gen.jpg"
                alt="Generatore BlueGen Anzio e Nettuno Alta efficienza"
              />
            </Col>
          </Row>
          <p>
            <u>
              <b>
                La Cella a Combustibile (nessuna combustione, ma reazione
                elettrochimica)
              </b>
            </u>{" "}
            <br />
            Il micro-cogeneratore di NSE srl utilizza la più moderna tecnologia
            delle celle a combustibile ad ossidi solidi o SOFC. È una tecnologia
            sensibilmente differente dalle altre tecnologie di generazione: i
            generatori convenzionali a combustione interna bruciano il
            combustibile e producono elettricità convertendo l’energia cinetica
            in energia elettrica.{" "}
            <u>
              Le celle a combustibile utilizzano invece una reazione
              elettrochimica per convertire in elettricità l’energia
              immagazzinata dal combustibile, processo decisamente più
              efficiente, oltre che silenzioso e senza vibrazioni
            </u>
            . È per questo che il cogeneratore può essere installato anche
            all’interno di appartamenti e case. Il modulo integrato della cella
            a combustibile combina tutti i componenti necessari in un singolo
            elemento – per la maggior effi cienza possibile ed una facile
            manutenzione.
          </p>
          <Row>
            <Col xs={12}>
              <img
                className="rounded mt-1 mx-auto img-fluid "
                src="../fuel/reazione.jpg"
                alt="Reazione Generatore BlueGen celle di combustibile"
              />
            </Col>
          </Row>
          <p>
            <u>
              <b>Facile installazione e manutenzione</b>
            </u>{" "}
            <br />
            Il micro-cogeneratore di NonsoloEnergia srl è facile da installare,
            richiede infatti soltanto delle connessioni standard alla rete
            elettrica e del gas, l’allacciamento idraulico ed una connessione
            internet a banda larga. Può essere installato sia in locali tecnici
            sia in altre aree funzionali, è compatto ed occupa uno spazio
            ridotto (come una lavatrice) e sarà così controllato e monitorato da
            remoto dal nostro personale tecnico. Questo ci permetterà di tenere
            sotto controllo la generazione di energia in modo costante, 24/7 e
            tu potrai verificarne le performance via web o smartphone.
            Manutenzione periodica e straordinaria inclusa con garanzia di
            funzionamento e di intervento di 10 anni.
          </p>
          <Row>
            <Col xs={12}>
              <img
                className="rounded mt-1 mx-auto img-fluid "
                src="../fuel/bluegen3.jpg"
                alt="Generatore BlueGen celle di combustibile"
              />
            </Col>
          </Row>
          <p>
            <u>
              <b>Tecnologia italiana</b>
            </u>{" "}
            <br />I sistemi di NSE srl sono prodotti in Italia, eccellenza
            mondiale nel campo della micro-cogenerazione a celle di combustibile
            a ossidi solidi (SOFC), le più affidabili, performanti e durature al
            mondo!
          </p>
        </CardPagina>
      </Sezione>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              CONSULENZA, PROGETTAZIONE, FINANZIAMENTO, REALIZZAZIONE E
              MANUTENZIONE:
              <br />
              Impianti a micro-cogenerazione a Celle di Combustibile Regione
              Lazio: <br />
              aziendali ed residenziali:
              <br />
              Roma, Pomezia, Aprilia, Latina, Cisterna,
              Anzio, Nettuno
              <br/>
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci"
              link="contatti"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col sm={6}>
            <img
              className="rounded mt-1 img-fluid"
              src="../fuel/bluegen1.jpg"
              alt="impianti a cogenerazione Lazio"
            />
          </Col>
          <Col sm={6}>
            <img
              className="rounded mt-1 img-fluid"
              src="../fuel/bluegen2.jpg"
              alt="impianti fotovoltaici Roma"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default FuelCells
